import React from 'react';

const DropIcon: React.FC = () => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="371.409px"
      height="371.409px"
      viewBox="0 0 371.409 371.409"
    >
      <g>
        <path d="M 270.265,149.448 C 234.158,102.324 199.885,70.5 196.826,8.076 196.43256,6.2891802 195.86974,3.9832154 195.13754,3.1280671 191.60911,-0.37353118 191.24764,-0.08429646 189.56128,0.11724729 186.30658,2.7949735 184.13517,4.5014753 181.527,7.464 104.415,90.696 -25.941,301.836 138.075,362.423 c 74.052,27.541 157.896,-9.791 172.584,-90.576 7.955,-43.451 -14.69,-89.35 -40.394,-122.399 z" />
      </g>
    </svg>
  );
};

export default DropIcon;
